var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customers"},[_c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',{staticClass:"title"},[_vm._v("Tous les contacts "+_vm._s(_vm.customers && _vm.customers.metadata && _vm.customers.metadata.count ? ("(" + (_vm.customers.metadata.count) + ")") : ''))])]),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToCustomer}},[_vm._v("Ajouter un client")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell shrink search"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher un client"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell medium-2 search"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies,"disabled":_vm.agencies.length === 0},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e(),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell shrink search"},[_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":_vm.initSearch}},[_vm._v("Réinitialiser")])],1):_vm._e(),_c('div',{staticClass:"cell shrink filter-container"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterCustomers,"reset":_vm.resetFilters,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-radio-button',{attrs:{"label":"Statut","name":"filterStatus","value":_vm.$route.query.status,"options":[
                  { name:'UNQUALIFIED', label: 'À qualifier'},
                  { name:'NEW', label: 'Nouveau'},
                  { name:'R1', label: 'R1'},
                  { name:'R2', label: 'R2'},
                  { name:'R3', label: 'R3'},
                  { name:'R4', label: 'R4'},
                  { name:'LAND_SEARCH', label: 'Recherche terrain'},
                  { name:'LAND_VISIT', label: 'Visite terrain'},
                  { name:'ABORTED', label: 'À archiver'},
                  { name:'CLIENT', label: 'Client'},
                  { name:'CONTRACT_SIGNATURE', label: 'Signature contrat'},
                  { name:'PURCHASE_AGREEMENT', label: 'Compromis d’achat'},
                  { name:'PENDING', label: 'En attente'},
                  { name:'FOLLOW_UP', label: 'À relancer'},
                  { name:'HOT_CUSTOMER', label: 'Opportunité'}
                ]},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-label',{staticClass:"label-period"},[_vm._v("Période")]),_c('app-label',[_vm._v("Du")]),_c('app-datepicker',{attrs:{"value":_vm.filters.startDate,"language":_vm.datePickerLanguage,"monday-first":"","required":!!(_vm.filters.endDate)},on:{"input":function($event){return _vm.handleFilterDateInput('startDate', $event)}}}),_c('app-label',{staticClass:"label-datepicker"},[_vm._v("Au")]),_c('app-datepicker',{attrs:{"value":_vm.filters.endDate,"language":_vm.datePickerLanguage,"monday-first":"","required":!!(_vm.filters.startDate)},on:{"input":function($event){return _vm.handleFilterDateInput('endDate', $event)}}})],1)])])],1)]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.customers.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToCustomer},scopedSlots:_vm._u([{key:"name",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.lastname))])]}},{key:"firstname",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.firstname))])]}},{key:"phone",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.phone.replace(/\D/g, "").replace(/(\d{2})(?=\d)/g, "$1 ")))])]}},{key:"email",fn:function(ref){
                var data = ref.data;
return [_c('p',[_c('span',{staticClass:"mailto",on:{"click":function($event){$event.stopPropagation();return _vm.openMailto(data.email)}}},[_vm._v(_vm._s(data.email))])])]}},{key:"status",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.CustomerStatus[data.status]))])]}},{key:"createdAt",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs.utc(data.createdAt).format('DD/MM/YYYY')))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.customers && _vm.customers.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.customers.metadata.offset,"count":_vm.customers.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }