<template>
  <div class="customers-unqualified hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>À qualifier {{ customers && customers.metadata && customers.metadata.count ? `(${customers.metadata.count})` : '' }}</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToCustomer">Ajouter un contact</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES CONTACT -->
    <div class="body">
      <div class="grid-x">
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher un contact" />
        </div>
        <div class="cell small-2 search" v-if="isBrandAdmin">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>

        <div class="cell shrink" v-if="isBrandAdmin">
          <app-button theme="secondary" size="small" @click="initSearch">Réinitialiser</app-button>
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterCustomers"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-select
                  label="Origin"
                  v-model="filters.firstContact"
                  :options="[
                    { name: 'PHONE', label: 'Téléphone' },
                    { name: 'WEBSITE', label: 'Site internet' },
                    { name: 'MAIL', label: 'Email' },
                    { name: 'BLOG', label: 'Blog' },
                    { name: 'UNKNOWN', label: 'Inconnue' },
                    { name: 'PHONE_SCORIMMO', label: 'Scorimmo' },
                    { name: 'LEBONCOIN_UBIFLOW', label: 'Ubiflow - Leboncoin - TMA' },
                    { name: 'LEBONCOIN_UBIFLOW_TA', label: 'Ubiflow - Leboncoin - TA' },
                    { name: 'LEBONCOIN_UBIFLOW_TMM', label: 'Ubiflow - Leboncoin - TMM' },
                    { name: 'LES_TERRAINS_UBIFLOW', label: 'Ubiflow - Les-Terrains.com ' },
                    { name: 'MOBILE_CONFIG', label: 'Configurateur Mobile' },
                    { name: 'OTHER_UBIFLOW', label: 'Ubiflow - Autres' },
                    { name: 'MANUAL_AGENCY_CREATION', label: 'Création Contact Agence' },
                    { name: 'EXPERT_CONFIG', label: 'Configurateur expert' },
                    { name: 'AGENCY_CONTACT_PAGE', label: 'Formulaire - Page Agence' }
                  ]"
                />
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-label class="label-period">Période</app-label>
                <app-label>Du</app-label>
                <app-datepicker
                :value="filters.startDate"
                @input="handleFilterDateInput('startDate', $event)"
                :language="datePickerLanguage"
                monday-first :required="!!(filters.endDate)"/>
                <app-label class="label-datepicker">Au</app-label>
                <app-datepicker
                :value="filters.endDate"
                  @input="handleFilterDateInput('endDate', $event)"
                  :language="datePickerLanguage"
                  monday-first :required="!!(filters.startDate)"/>
              </div>
            </div>
          </filter-modal>
        </div>
      </div>
      <app-table :headers="tableHeaders" :data="customers.data" :loading="isLoading" @line-cliked="goToCustomer" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <p>{{ data.lastname }}</p>
        </template>
        <template slot="firstname" slot-scope="{ data }">
          <p>{{ data.firstname }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone.replace(/\D/g, "").replace(/(\d{2})(?=\d)/g, "$1 ") }}</p>
        </template>
        <template slot="email" slot-scope="{ data }">
          <p><span class="mailto" @click.stop="openMailto(data.email)">{{ data.email }}</span></p>
        </template>
        <template slot="firstContact" slot-scope="{ data }">
          <p>{{ customerFirstContacts.find(c => c.name === data.firstContact).label }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs.utc(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
      </app-table>
      <app-pagination
        v-if="customers.metadata"
        :limit="limit"
        :offset="customers.metadata.offset"
        :count="customers.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import customerApi from '@/services/api/customer';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

import FilterModal from '@/components/general/FilterModal.vue';

import utils from '@/services/utils/utils';
import customerFirstContacts from '@/services/data/customerFirstContacts.json';

export default {
  name: 'customers-unqualified',
  components: {
    FilterModal,
  },
  metaInfo: {
    title: 'À qualifier',
  },
  data() {
    return {
      customerFirstContacts,
      customers: [],
      limit: 10,
      agencies: [],
      search: null,
      isLoading: null,
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Prénom', key: 'firstname', size: 2 },
        { label: 'Téléphone', key: 'phone', size: 2 },
        { label: 'Mail', key: 'email', size: 'auto' },
        { label: 'Origine', key: 'firstContact', size: 2 },
        { label: 'Crée le', key: 'createdAt', size: 1 },
      ],
      filters: {
        startDate: null,
        endDate: null,
        firstContact: null,
      },
      datePickerLanguage: 'fr',
      me: null,
      isBrandAdmin: null,
      agencyId: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    this.search = this.$route.query.search;
    this.filters.startDate = this.$route.query.startDate || null;
    this.filters.endDate = this.$route.query.endDate || null;
    this.filters.firstContact = this.$route.query.firstContact || null;

    await this.getCustomers(this.computeOffset);
    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;

    if (this.isBrandAdmin) {
      await this.getAgencies();
    }
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getCustomers(this.computeOffset);
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    filters: {
      handler(value) {
        this.filters.startDate = value.startDate;
        this.filters.endDate = value.endDate;
        this.filters.firstContact = value.firstContact;
      },
      deep: true,
    },
  },
  methods: {
    openMailto(email) {
      this.$notification.show({ text: 'L’adresse mail a été transférée à votre client mail configuré par défaut.' });

      const subject = 'Maisons Alysia';
      const body = 'Bonjour,';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Créer un élément <a> dynamiquement (Astuce target blank)
      const a = document.createElement('a');
      a.href = mailtoLink;
      a.target = '_blank';
      a.click();
    },
    initSearch() {
      this.agencyId = undefined;
      this.search = undefined;

      if (this.$route.query.search !== this.search || this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: undefined,
            agencyId: undefined,
            page: 1,
          },
        });
      }
    },
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    handleFilterDateInput(filterName, date) {
      this.filters[filterName] = date ? this.$dayjs(date).toISOString() : undefined;
    },
    goToCustomer(customer) {
      if (customer && customer.customerId) {
        this.$router.push({ name: 'customers-unqualified-customerId', params: { customerId: customer.customerId } });
      } else {
        this.$router.push({ name: 'customers-unqualified-new' });
      }
    },
    async getCustomers(offset) {
      try {
        this.customers = await customerApi.getAll(this.limit, offset, this.search, ['UNQUALIFIED'], this.agencyId, null, this.filters.startDate, this.filters.endDate, this.filters.firstContact);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des clients',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    async filterCustomers() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.filters.startDate || undefined,
          endDate: this.filters.endDate || undefined,
          firstContact: this.filters.firstContact || undefined,
          page: 1,
        },
      });
    },
    resetFilters() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        startDate: null,
        endDate: null,
        firstContact: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          startDate: undefined,
          endDate: undefined,
          firstContact: undefined,
        },
      });
    },
    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.startDate = this.$route.query.startDate || null;
      this.filters.endDate = this.$route.query.endDate || null;
      this.filters.firstContact = this.$route.query.firstContact || null;
    },
    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.customers-unqualified
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .filter-container
    margin-left: auto
  .filter-btn
    margin-left: 1rem
  .search
    padding-bottom: 1rem
    margin-right: 1rem
  .label-period
    margin-bottom: 1rem
  .label-datepicker
    margin-top: 0.5rem
  .mailto
    color: $blue-medium
</style>
