var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-drafts"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToCreateProjectDraft}},[_vm._v(" Ajouter un avant-projet ")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x"},[(_vm.isBrandAdmin)?_c('div',{staticClass:"cell small-2 search"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e(),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":_vm.initSearch}},[_vm._v("Réinitialiser")])],1):_vm._e(),_c('div',{staticClass:"cell shrink filter-container"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterProjectDrafts,"reset":_vm.resetFilters,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-select',{attrs:{"label":"statut","value":_vm.$route.query.status,"options":_vm.projectDraftStatuses},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)])])],1)]),_c('div',[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.projectDrafts.data,"loading":_vm.isLoading,"isDisabledIf":_vm.isDisabledIf,"isClickableIf":_vm.isClickableIf,"clickable":""},on:{"line-cliked":_vm.goToProjectDraft},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"customer",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.customer && data.customer.customerId ? _vm.getCustomersNames(data.customer) : '-'))])]}},{key:"city",fn:function(ref){
var data = ref.data;
return [(data.land && data.land.address && data.land.address.city)?_c('p',[_vm._v(_vm._s(data.land.address.city))]):(data.customerLand && data.customerLand.address && data.customerLand.address.city)?_c('p',[_vm._v(_vm._s(data.customerLand.address.city))]):_vm._e()]}},{key:"allotment",fn:function(ref){
var data = ref.data;
return [(data.land.allotment && data.land.allotment.allotmentId)?_c('p',[_c('small',[_vm._v(" "+_vm._s(data.land.allotment.name)+" "),(data.land.lotNumber)?_vm._t("default",[_vm._v("- Lot "+_vm._s(data.land.lotNumber))]):_vm._e()],2)]):_vm._e()]}},{key:"version",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.version.name))])]}},{key:"land",fn:function(ref){
var data = ref.data;
return [(data.land && data.land.landId)?_c('p',[_vm._v(" "+_vm._s(((data.land.surface) + " m²"))+" ")]):(data.customerLand)?_c('p',[_vm._v(" "+_vm._s(((data.customerLand.surface) + " m²"))+" ")]):_c('p',[_vm._v("-")])]}},{key:"landPrice",fn:function(ref){
var data = ref.data;
return [(data.land && data.land.landId)?_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.land.price)) + " €"))+" ")]):(data.customerLand)?_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.customerLand.price)) + " €"))+" ")]):_c('p',[_vm._v("-")])]}},{key:"status",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.status ? _vm.projectDraftStatuses.find(function (status) { return status.name === data.status; }).label : ''))])]}},{key:"totalPrice",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.totalPrice)) + " €"))+" ")])]}},{key:"date",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.updatedAt).format('DD/MM/YYYY')))])]}}],null,true)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucun avant-projet disponible")])])],2)],1),(_vm.projectDrafts.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.projectDrafts.metadata.offset,"count":_vm.projectDrafts.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Liste des avant-projets")])])}]

export { render, staticRenderFns }