var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-projects hedifys-21"},[_c('form',{ref:"customer-projects",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"breadcrumbs grid-x row"},[_c('div',{staticClass:"cell auto"},[(_vm.$route.params.customerId && _vm.customer && _vm.customer.lastname)?_c('breadcrumb',{attrs:{"items":[
            {
              route: {
                name: _vm.routeStatus
              },
              name: _vm.routeStatus  === 'customers-unqualified' ? 'À qualifier' : _vm.routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
            },
            { route: { name: (_vm.routeStatus + "-customerId"),
                params: { customerId: _vm.$route.params.customerId }},
              name: ((_vm.customer.firstname) + " " + (_vm.customer.lastname))
            }
          ]}}):_vm._e()],1)]),_c('div',{staticClass:"new-header grid-x"},[_c('div',{staticClass:"cell shrink"},[_c('h1',[_vm._v(_vm._s(((_vm.customer.firstname) + " " + (_vm.customer.lastname))))])]),_c('div',{staticClass:"cell auto tag-container"},[_c('h5',{staticClass:"tag",class:_vm.tagColor},[_vm._v(_vm._s(_vm.getCustomerStatusName(_vm.customer.status)))])]),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToCreateProjectDraft}},[_vm._v(" Ajouter un avant-projet ")])],1)]),(_vm.$route.params.customerId)?_c('tab-nav',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(!_vm.isLoading && _vm.customer)?_c('div',{staticClass:"body"},[_c('div',[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.projectDrafts.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToProjectDraft},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"city",fn:function(ref){
          var data = ref.data;
return [(data.land && data.land.address && data.land.address.city)?_c('p',[_vm._v(_vm._s(data.land.address.city))]):(data.customerLand && data.customerLand.address && data.customerLand.address.city)?_c('p',[_vm._v(_vm._s(data.customerLand.address.city))]):_vm._e()]}},{key:"reference",fn:function(ref){
          var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.version.reference))])]}},{key:"allotment",fn:function(ref){
          var data = ref.data;
return [(data.land.allotment.allotmentId)?_c('p',[_c('small',[_vm._v(" "+_vm._s(data.land.allotment.name)+" "),(data.land.lotNumber)?_vm._t("default",[_vm._v("- Lot "+_vm._s(data.land.lotNumber))]):_vm._e()],2)]):_vm._e()]}},{key:"land",fn:function(ref){
          var data = ref.data;
return [(data.land && data.land.landId)?_c('p',[_vm._v(" "+_vm._s(((data.land.surface) + " m²"))+" ")]):(data.customerLand)?_c('p',[_vm._v(" "+_vm._s(((data.customerLand.surface) + " m²"))+" ")]):_c('p',[_vm._v("-")])]}},{key:"landPrice",fn:function(ref){
          var data = ref.data;
return [(data.land && data.land.landId)?_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.land.price)) + " €"))+" ")]):(data.customerLand)?_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.customerLand.price)) + " €"))+" ")]):_c('p',[_vm._v("-")])]}},{key:"status",fn:function(ref){
          var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.status ? _vm.projectDraftStatuses.find(function (status) { return status.name === data.status; }).label : ''))])]}},{key:"totalPrice",fn:function(ref){
          var data = ref.data;
return [_c('p',[_vm._v(" "+_vm._s(((_vm.utils.formatCentToEuro(data.totalPrice)) + " €"))+" ")])]}},{key:"date",fn:function(ref){
          var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.updatedAt).format('DD/MM/YYYY')))])]}}],null,true)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucun avant-projet disponible")])])],2)],1),(_vm.projectDrafts.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.projectDrafts.metadata.offset,"count":_vm.projectDrafts.metadata.count}}):_vm._e()],1):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }