<template>
  <div class='lands hedifys-21'>
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Liste des terrains</h1>
      </div>
      <div class="cell shrink">
        <app-button
          theme="primary"
          size="large"
          icon="add"
          @click="goToCreateLand"
        >
          Ajouter un terrain
        </app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <!-- CONTENT -->
      <div class="grid-x">
        <!-- SEARCH BAR -->
        <div class="cell auto search">
          <app-search
            v-model="search"
            placeholder="Rechercher une référence, un lotissement, un lotisseur"
            size="auto"
          />
        </div>

        <div class="cell small-2 search" v-if="isBrandAdmin">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>

        <div class="cell shrink" v-if="isBrandAdmin">
          <app-button theme="secondary" size="small" @click="initSearch">Réinitialiser</app-button>
        </div>

        <div class="cell auto"/>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterLands"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-radio-button
                  label="Trier par"
                  name="orderBy"
                  :value="$route.query.orderBy"
                  v-model="filters.orderBy"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: 'width', label: 'Façade croissante' },
                    { name: 'name', label: 'Nom de commune' },
                    { name: 'lot', label: 'Numéro de lot croissant' },
                    { name: 'surface', label: 'Surface croissante' },
                    { name: 'surface_desc', label: 'Surface décroissante' },
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-radio-button
                  label="Status du terrain"
                  name="status"
                  :value="$route.query.status"
                  v-model="filters.status"
                  :options="[
                    { name: 'ACTIVE', label: 'Actif' },
                    { name: 'UNAVAILABLE', label: 'Indisponible' },
                    { name: 'SOLD', label: 'Vendu' }
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-search-city
                  label="Commune"
                  placeholder="Nantes - 44000"
                  @select="setPreSelectedPostalCode"
                  @clear="resetSelectedPostalCode"
                  :selectedPostalCode="selectedPostalCode"
                />
              </div>
            </div>
          </filter-modal>
        </div>

        <!-- TOGGLE -->
        <div class="cell shrink align-self-middle">
          <app-toggle
            name='toggle'
            :icons='["list", "locate"]'
            v-model="selectedView"
            :selectedIcon="$route.query.view"
            @input="updateRoute"
          />
        </div>
      </div>

      <!-- TABLE -->
      <div v-if="$route.query.view === 'list' || !$route.query.view">
        <app-table
          :headers="tableHeader"
          :data="lands.data"
          :loading="isLoading"
          @line-cliked="goToLand"
          clickable
        >
          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="city" slot-scope="{ data }">
            <strong>
              {{ data.address.postalCodeInseeNumber.split('-')[0] }} - {{ data.address.city }}
            </strong>
          </template>
          <template slot="allotment" slot-scope="{ data }">
            <p>{{ data.allotment ? data.allotment.name : '' }}</p>
          </template>
          <template slot="company" slot-scope="{ data }">
            <p :class="{'no-registered-company-color' : !data.isCompanyRegistered}">{{ data.company ? data.company.name : '' }}</p>
          </template>
          <template slot="reference" slot-scope="{ data }">
            <p>{{ data.reference }}</p>
          </template>
          <template slot="isAvailableForAds" slot-scope="{ data }">
            <p>{{ booleanEnum[data.isAvailableForAds] }}</p>
          </template>
          <template slot="status" slot-scope="{ data }">
            <h5 class='tag' :class="[`tag-${status[data.status].tag}`]">
              <success v-if="status[data.status].tag === 'success'"/>
              <minus v-if="status[data.status].tag === 'warning'"/>
              <price v-if="status[data.status].tag === 'neutral'"/>
              {{ status[data.status].label }}
            </h5>
          </template>
          <template slot="lotNumber" slot-scope="{ data }">
            <p>{{ data.lotNumber }}</p>
          </template>
          <template slot="depth" slot-scope="{ data }">
            <p>{{ data.width }} m</p>
          </template>
          <template slot="surface" slot-scope="{ data }">
            <strong>{{ data.surface }} m²</strong>
          </template>
          <template slot="price" slot-scope="{ data }">
            <strong class="price">{{ utils.formatCentToEuro(data.price, true) }}</strong>
          </template>
          <template slot="empty-table">
            <p>Aucune donnée de disponible</p>
          </template>
        </app-table>
      </div>

      <!-- MAP -->
      <land-map
        v-else
        :lands="landsCoordinates"
        :height="500"
      />

      <!-- PAGINATION -->
      <app-pagination
        v-if="lands.metadata"
        :limit="limit"
        :offset="lands.metadata.offset"
        :count="lands.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import landApi from '@/services/api/land';
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';
import booleanEnum from '@/services/enums/booleanEnum.enum';
import status from '@/services/data/status.json';
import FilterModal from '@/components/general/FilterModal.vue';
import LandMap from '@/components/general/LandMap.vue';
import AppSearchCity from '@/basics/components/AppSearchCity.vue';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

import success from '@/assets/img/success.svg?inline';
import minus from '@/assets/img/minus.svg?inline';
import price from '@/assets/img/price.svg?inline';

export default {
  name: 'lands',

  components: {
    FilterModal,
    AppSearchCity,
    LandMap,
    success,
    minus,
    price,
  },

  metaInfo: {
    title: 'Liste des terrains',
  },

  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Ville', key: 'city', size: 'auto' },
        { label: 'Lotissement', key: 'allotment', size: 'auto' },
        { label: 'Lotisseur', key: 'company', size: 1 },
        { label: 'Référence', key: 'reference', size: 1 },
        { label: 'Diffusion', key: 'isAvailableForAds', size: 1 },
        { label: 'Statut', key: 'status', size: 'auto' },
        { label: 'Lot', key: 'lotNumber', size: 1 },
        { label: 'Façade', key: 'depth', size: 1 },
        { label: 'Surface', key: 'surface', size: 1 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      isLoading: false,

      lands: {
        data: null,
        metadata: null,
      },
      land: {},
      landsCoordinates: [],

      preSelectedPostalCode: null,
      selectedPostalCode: null,
      showCreateLandModale: false,
      search: null,

      filters: {
        orderBy: null,
        postalCodeInseeNumber: null,
        status: null,
      },
      limit: 10,
      booleanEnum,
      status,
      utils,
      selectedView: null,
      isBrandAdmin: null,
      agencyId: null,
      agencies: [],
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
    this.selectedPage = parseInt(this.$route.query.page, 10);
  },

  async mounted() {
    this.isLoading = true;

    this.search = this.$route.query.search || null;
    this.selectedView = this.$route.query.view || undefined;
    this.filters.orderBy = this.$route.query.orderBy || null;
    this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;
    this.filters.status = this.$route.query.status || null;

    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;
    this.agencyId = this.$route.query.agencyId;

    if (this.$route.query.postalCodeInseeNumber) {
      this.getPostalCode(this.$route.query.postalCodeInseeNumber);
    }

    if (this.isBrandAdmin) {
      await this.getAgencies();
    }

    await this.getLands();

    this.isLoading = false;
  },

  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getLands();
        this.isLoading = false;
      }
    },

    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.orderBy = value.orderBy;
        this.filters.postalCodeInseeNumber = value.postalCodeInseeNumber;
        this.filters.status = value.status;
      },
      deep: true,
    },

    selectedView() {
      this.updateRoute();
    },
  },

  computed: {
    // Calcule de l'offset
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },

  methods: {
    initSearch() {
      this.agencyId = undefined;
      this.search = undefined;

      if (this.$route.query.search !== this.search || this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: undefined,
            agencyId: undefined,
            page: 1,
          },
        });
      }
    },
    // On met à jour la query de recherche générale
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            page: 1,
          },
        });
      }
    },

    // Récupération des terrains
    async getLands() {
      try {
        this.lands = await landApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          this.$route.query.orderBy,
          this.$route.query.agencyId,
          null,
          this.$route.query.postalCodeInseeNumber,
          null,
          this.$route.query.status,
        );

        // On appel la fonction pour extraire les coordonnées de chaque terrains si il y en a
        this.getLandsDataForMap();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des terrains',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération du code postal sélectionné
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupère le code postal sélectionné
    setPreSelectedPostalCode(value) {
      this.preSelectedPostalCode = value.label;
      this.filters.postalCodeInseeNumber = value.name;
    },

    async filterLands() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      if ((this.$route.query.orderBy !== this.filters.orderBy && (this.filters.orderBy !== null || this.$route.query.orderBy !== undefined))
        || (this.$route.query.postalCodeInseeNumber !== this.filters.postalCodeInseeNumber && (this.filters.postalCodeInseeNumber !== null || this.$route.query.postalCodeInseeNumber !== undefined))
        || (this.$route.query.status !== this.filters.status && (this.filters.status !== null || this.$route.query.status !== undefined))
      ) {
        this.$router.push({
          query: {
            ...this.$route.query,
            orderBy: this.filters.orderBy || undefined,
            postalCodeInseeNumber: this.filters.postalCodeInseeNumber || undefined,
            status: this.filters.status || undefined,
            page: 1,
          },
        });
      }

      if (this.preSelectedPostalCode) {
        this.selectedPostalCode = this.preSelectedPostalCode;
      }

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }
    },

    // Extrait les coordonnées des terrains et de leur id pour la carte
    getLandsDataForMap() {
      if (this.lands && this.lands.data && this.lands.data.length) {
        this.landsCoordinates = this.lands.data.map((land) => ({
          landId: land.landId,
          coordinates: land.coordinates,
        }));
      } else {
        this.landsCoordinates = [];
      }
    },

    resetFilters() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        orderBy: null,
        postalCodeInseeNumber: null,
        status: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      if ((this.$route.query.orderBy !== this.filters.orderBy && (this.filters.orderBy || this.$route.query.orderBy))
        || (this.$route.query.postalCodeInseeNumber !== this.filters.postalCodeInseeNumber && (this.filters.postalCodeInseeNumber || this.$route.query.postalCodeInseeNumber))
        || (this.$route.query.status !== this.filters.status && (this.filters.status || this.$route.query.status))
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            orderBy: undefined,
            postalCodeInseeNumber: undefined,
            status: undefined,
          },
        });
      }

      // On réinitialise les valeurs du label de la commune sélectionné
      this.preSelectedPostalCode = null;
      this.selectedPostalCode = null;
    },

    // Reset les valeurs du code postal sélectionné si le champ de recherche est vide
    resetSelectedPostalCode() {
      this.filters.postalCodeInseeNumber = null;
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.orderBy = this.$route.query.orderBy || null;
      this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;
      this.filters.status = this.$route.query.status || null;

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }
    },

    // On met à jour la query de la vue sélectionné
    updateRoute() {
      if (this.selectedView !== this.$route.query.view) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            view: this.selectedView,
          },
        });
      }
    },

    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },

    // Redirection vers la page de création d'un terrain
    goToCreateLand() {
      this.$router.push({ name: 'lands-new' });
    },

    // Redirection vers la page d'édition d'un terrain
    goToLand(land) {
      this.$router.push({ name: 'lands-landId', params: { landId: land.landId } });
    },
  },
};
</script>

<style lang='sass' scoped>
.lands
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .price
    @include price

  .search
    padding-bottom: 1rem
    margin-right: 1rem
    button
      margin-left: 1rem

  .filter-container
    display: flex
    align-items: center
    justify-content: center
    margin-right: 1rem
  .filter-btn
    margin-left: 1rem

  .edit-land
    .edit-land-input-container
      margin-bottom: 1rem
      &:first-of-type, &:last-of-type
        margin-top: 1rem

    .edit-land-input-submit-container
      margin-top: 3rem

    .edit-land-label
      font-weight: 500
      margin-bottom: .5rem
      &:first-of-type
        margin-top: .5rem

  .no-registered-company-color
    color: red
</style>
