const CustomerStatus = Object.freeze({
  UNQUALIFIED: 'À qualifier',
  NEW: 'Nouveau',
  R1: 'R1',
  R2: 'R2',
  R3: 'R3',
  R4: 'R4',
  LAND_SEARCH: 'Recherche terrain',
  LAND_VISIT: 'Visite terrain',
  ABORTED: 'À archiver',
  CLIENT: 'Client',
  CONTRACT_SIGNATURE: 'Signature contrat',
  PURCHASE_AGREEMENT: "Compromis d'achat",
  PENDING: 'En attente',
  FOLLOW_UP: 'À relancer',
  HOT_CUSTOMER: 'Opportunité',
});

export default CustomerStatus;
