<template>
  <div class="customer-projects hedifys-21">
    <form ref="customer-projects" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="$route.params.customerId && customer && customer.lastname"
            :items="[
              {
                route: {
                  name: routeStatus
                },
                name: routeStatus  === 'customers-unqualified' ? 'À qualifier' : routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
              },
              { route: { name: `${routeStatus}-customerId`,
                  params: { customerId: $route.params.customerId }},
                name: `${customer.firstname} ${customer.lastname}`
              }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="new-header grid-x">
        <div class="cell shrink">
          <h1>{{ `${customer.firstname} ${customer.lastname}` }}</h1>
        </div>
        <div class="cell auto tag-container">
          <h5 class="tag" :class="tagColor">{{ getCustomerStatusName(customer.status) }}</h5>
        </div>
        <div class="cell shrink">
          <app-button
            theme="primary"
            size="large"
            icon="add"
            @click="goToCreateProjectDraft"
          >
            Ajouter un avant-projet
          </app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" v-if="$route.params.customerId" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && customer">
        <!-- TABLE -->
        <div>
          <app-table
            :headers="tableHeader"
            :data="projectDrafts.data"
            :loading="isLoading"
            @line-cliked="goToProjectDraft"
            clickable
          >
            <template slot="loading">
              <app-spinner />
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
            </template>
            <template slot="city" slot-scope="{ data }">
              <p v-if="data.land && data.land.address && data.land.address.city">{{ data.land.address.city  }}</p>
              <p v-else-if="data.customerLand && data.customerLand.address && data.customerLand.address.city">{{ data.customerLand.address.city  }}</p>
            </template>
            <template slot="reference" slot-scope="{ data }">
              <p>{{ data.version.reference }}</p>
            </template>
            <template slot="allotment" slot-scope="{ data }">
              <p v-if="data.land.allotment.allotmentId">
                <small>
                  {{ data.land.allotment.name }}
                  <slot v-if="data.land.lotNumber">- Lot {{ data.land.lotNumber }}</slot>
                </small>
              </p>
            </template>
            <template slot="land" slot-scope="{ data }">
              <p v-if="data.land && data.land.landId">
                {{ `${data.land.surface} m²`}}
              </p>
              <p v-else-if="data.customerLand">
                {{ `${data.customerLand.surface} m²`}}
              </p>
              <p v-else>-</p>
            </template>
            <template slot="landPrice" slot-scope="{ data }">
              <p v-if="data.land && data.land.landId">
                {{ `${utils.formatCentToEuro(data.land.price)} €`}}
              </p>
              <p v-else-if="data.customerLand">
                {{ `${utils.formatCentToEuro(data.customerLand.price)} €`}}
              </p>
              <p v-else>-</p>
            </template>
            <template slot="status" slot-scope="{ data }">
              <p>{{ data.status ? projectDraftStatuses.find((status) => status.name === data.status).label : '' }}</p>
            </template>
            <template slot="totalPrice" slot-scope="{ data }">
              <p>
                {{ `${utils.formatCentToEuro(data.totalPrice)} €`}}
              </p>
            </template>
            <template slot="date" slot-scope="{ data }">
            <p>{{ $dayjs(data.updatedAt).format('DD/MM/YYYY') }}</p>
          </template>
            <template slot="empty-table">
              <p>Aucun avant-projet disponible</p>
            </template>
          </app-table>
        </div>
        <!-- PAGINATION -->
        <app-pagination
          v-if="projectDrafts.metadata"
          :limit="limit"
          :offset="projectDrafts.metadata.offset"
          :count="projectDrafts.metadata.count"
        />
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import customerApi from '@/services/api/customer';
import customerStatuses from '@/services/data/customerStatuses.json';
import projectDraftApi from '@/services/api/projectDraft';
import projectDraftStatuses from '@/services/data/projectDraftStatuses.json';

import statuses from '@/services/data/statuses.json';
import utils from '@/services/utils/utils';

export default {
  name: 'customer-projects',
  components: {
    TabNav,
    Breadcrumb,
  },
  data() {
    return {
      isLoading: false,
      customerStatuses,
      projects: [],
      statuses,
      customer: {
        agencyId: null,
        gender: null,
        lastname: null,
        firstname: null,
        secondaryContactGender: null,
        secondaryContactLastname: null,
        secondaryContactFirstname: null,
        type: 'COMPANY',
        companyName: null,
        companySiret: null,
        address: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        status: 'UNQUALIFIED',
        firstContact: 'UNKNOWN',
      },
      tableHeader: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Commune', key: 'city', size: 'auto' },
        { label: 'Version', key: 'reference', size: 2 },
        { label: 'Lotissement', key: 'allotment', size: 2 },
        { label: 'Surface T.', key: 'land', size: 'auto' },
        { label: 'Prix T.', key: 'landPrice', size: 'auto' },
        { label: 'Status', key: 'status', size: 'auto' },
        { label: 'Prix Total', key: 'totalPrice', size: 'auto' },
        { label: 'Date', key: 'date', size: 'auto' },
      ],
      projectDrafts: {
        data: null,
        metadata: null,
      },
      limit: 10,
      projectDraftStatuses,
      genders: [
        { value: 'MALE', label: 'M.' },
        { value: 'FEMALE', label: 'Mme' },
      ],
      utils,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getCustomer();
    await this.getProjectDrafts();
    this.isLoading = false;
  },
  computed: {
    // Calcule de l'offset
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    routeStatus() {
      // on déduit les liens, à partir de la route actuelle
      const routeStatus = this.$route.name.split('-')[1];
      if (routeStatus === 'unqualified' || routeStatus === 'prospects') {
        return `customers-${routeStatus}`;
      }
      return 'projects-customers';
    },
    tagColor() {
      return {
        'tag-primary': this.customer.status === 'CLIENT',
        'tag-secondary': this.customer.status !== 'CLIENT' && this.customer.status !== 'UNQUALIFIED',
        'tag-neutral': this.customer.status === 'UNQUALIFIED',
      };
    },
    tabs() {
      // initialisation des tabs
      const tabs = [
        {
          route: {
            name: `${this.routeStatus}-customerId`,
            params: { customerId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-draft-projects`,
            params: { customerId: null },
          },
          label: 'Avant-projets',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-contact`,
            params: { customerId: null },
          },
          label: 'Prise de contact',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-agenda`,
            params: { customerId: null },
          },
          label: 'Agenda',
        },
      ];
      // Pour les clients, on a ajoute les projets
      if (this.routeStatus === 'projects-customers') {
        tabs.push(
          {
            route: {
              name: `${this.routeStatus}-customerId-projects`,
              params: { customerId: null },
            },
            label: 'Projets',
          },
        );
      }
      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { customerId: this.$route.params.customerId },
        },
        label: el.label,
      }));
    },
  },
  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getProjectDrafts();
        this.isLoading = false;
      }
    },
  },
  methods: {
    async getCustomer() {
      try {
        if (this.$route.params.customerId) {
          this.customer = await customerApi.getById(this.$route.params.customerId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getProjectDrafts() {
      try {
        if (this.$route.params.customerId) {
          this.projectDrafts = await projectDraftApi.getByCustomer(
            this.limit,
            this.computeOffset,
            this.$route.params.customerId,
          );
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des avant-projets',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getCustomerStatusName(status) {
      const customer = customerStatuses.find((el) => el.name === status);
      return customer ? customer.label : null;
    },

    // Redirection vers la page de création d'un avant-projet
    goToCreateProjectDraft() {
      this.$router.push({ name: 'customers-projects-drafts-new', query: { customerId: this.$route.params.customerId } });
    },

    // Redirection vers la page d'édition d'un terrain
    goToProjectDraft(projectDraft) {
      const route = this.$router.resolve({ name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: projectDraft.projectDraftId } });
      window.open(route.href, '_blank');
    },

    // Récupération du nom du client
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
  },
};
</script>

<style lang='sass' scoped>
.customer-projects
  .body
    @include screen
  .new-header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin-bottom: 10px
  .tag-container
    display: flex
    align-items: center
    margin-left: 16px
    > h5
      height: fit-content
  .tag-primary
    @include tag-primary
  .tag-secondary
    @include tag-secondary
  .form-section
    margin-bottom: 10px
</style>
