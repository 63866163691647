<template>
  <div class="customers-prospects">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Prospects {{ customers && customers.metadata && customers.metadata.count ? `(${customers.metadata.count})` : '' }}</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToCustomer">Ajouter un contact</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES CONTACT -->
    <div class="body">
      <div class="grid-x">
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher un contact" />
        </div>
        <div class="cell small-2 search" v-if="isBrandAdmin">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>

        <div class="cell shrink search" v-if="isBrandAdmin">
          <app-button theme="secondary" size="small" @click="initSearch">Réinitialiser</app-button>
        </div>

        <div class="cell auto"></div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterProspects"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Statut"
                  v-model="filters.status"
                  :options="customerStatuses"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-label class="label-period">Période</app-label>
                <app-label>Du</app-label>
                <app-datepicker
                :value="filters.startDate"
                @input="handleFilterDateInput('startDate', $event)"
                :language="datePickerLanguage"
                monday-first :required="!!(filters.endDate)"/>
                <app-label class="label-datepicker">Au</app-label>
                <app-datepicker
                :value="filters.endDate"
                  @input="handleFilterDateInput('endDate', $event)"
                  :language="datePickerLanguage"
                  monday-first :required="!!(filters.startDate)"/>
              </div>
            </div>
          </filter-modal>
        </div>
        <div class="cell shrink">
          <DropdownActionsExport
            title="Exporter"
            :actions="dropdownContent"
            :hasArrow="true"
          />
        </div>
      </div>
      <app-table :headers="tableHeaders" :data="customers.data" :loading="isLoading" @line-cliked="goToCustomer" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="reference" slot-scope="{ data }">
          <strong>{{ data.reference }}</strong>
        </template>
        <template slot="name" slot-scope="{ data }">
          <p>{{ data.lastname }}</p>
        </template>
        <template slot="firstname" slot-scope="{ data }">
          <p>{{ data.firstname }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone.replace(/\D/g, "").replace(/(\d{2})(?=\d)/g, "$1 ") }}</p>
        </template>
        <template slot="email" slot-scope="{ data }">
          <p><span class="mailto" @click.stop="openMailto(data.email)">{{ data.email }}</span></p>
        </template>
        <template slot="status" slot-scope="{ data }">
          <p>{{ CustomerStatus[data.status] }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs.utc(data.createdAt).format('DD/MM/YYYY')  }}</p>
        </template>
      </app-table>
      <app-pagination
        v-if="customers.metadata"
        :limit="limit"
        :offset="customers.metadata.offset"
        :count="customers.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import customerApi from '@/services/api/customer';
import customerEventApi from '@/services/api/customerEvent';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

import FilterModal from '@/components/general/FilterModal.vue';

import customerStatuses from '@/services/data/customerStatuses.json';
import utils from '@/services/utils/utils';
import DropdownActionsExport from '@/components/general/DropdownActionsExport.vue';
import CustomerStatus from '@/services/enums/customerStatus.enum';

export default {
  name: 'customers-prospects',
  components: {
    FilterModal,
    DropdownActionsExport,
  },
  metaInfo: {
    title: 'Prospects',
  },
  data() {
    return {
      CustomerStatus,
      customers: [],
      limit: 10,
      agencies: [],
      search: null,
      isLoading: null,
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Prénom', key: 'firstname', size: 2 },
        { label: 'Téléphone', key: 'phone', size: 2 },
        { label: 'Mail', key: 'email', size: 'auto' },
        { label: 'Statut', key: 'status', size: 2 },
        { label: 'Crée le', key: 'createdAt', size: 1 },
      ],
      customerStatuses,
      me: null,
      isBrandAdmin: null,
      agencyId: null,
      status: null,
      filters: {
        status: null,
        startDate: null,
        endDate: null,
      },
      dropdownContent: [
        {
          title: 'Par clients',
          callback: this.downloadCsvCustomers,
        },
        {
          title: 'Par activité commerciale',
          callback: this.downloadCsvEvents,
        },
      ],
      datePickerLanguage: 'fr',
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    this.search = this.$route.query.search;
    this.filters.status = this.$route.query.status || null;
    this.filters.startDate = this.$route.query.startDate || null;
    this.filters.endDate = this.$route.query.endDate || null;
    this.customerStatuses = this.customerStatuses.filter((el) => el.name !== 'UNQUALIFIED' && el.name !== 'CLIENT');
    await this.getCustomers(this.computeOffset);
    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;

    if (this.isBrandAdmin) {
      await this.getAgencies(this.computeOffset);
    }
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getCustomers(this.computeOffset);
        this.isLoading = false;
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.status = value.status;
        this.filters.startDate = value.startDate;
        this.filters.endDate = value.endDate;
      },
      deep: true,
    },
  },
  methods: {
    openMailto(email) {
      this.$notification.show({ text: 'L’adresse mail a été transférée à votre client mail configuré par défaut.' });

      const subject = 'Maisons Alysia';
      const body = 'Bonjour,';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Créer un élément <a> dynamiquement (Astuce target blank)
      const a = document.createElement('a');
      a.href = mailtoLink;
      a.target = '_blank';
      a.click();
    },
    initSearch() {
      this.agencyId = undefined;
      this.search = undefined;

      if (this.$route.query.search !== this.search || this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: undefined,
            agencyId: undefined,
            page: 1,
          },
        });
      }
    },
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    goToCustomer(customer) {
      if (customer && customer.customerId) {
        this.$router.push({ name: 'customers-prospects-customerId', params: { customerId: customer.customerId } });
      } else {
        this.$router.push({ name: 'customers-prospects-new' });
      }
    },
    handleFilterDateInput(filterName, date) {
      this.filters[filterName] = date ? this.$dayjs(date).toISOString() : undefined;
    },
    getCustomerStatusName(status) {
      return customerStatuses.find((el) => el.name === status).label;
    },
    async getCustomers(offset) {
      try {
        const status = !this.filters.status ? this.customerStatuses.filter((s) => s.name !== 'UNQUALIFIED').map((s) => s.name) : [this.filters.status];
        this.customers = await customerApi.getAll(this.limit, offset, this.search, status, this.agencyId, null, this.filters.startDate, this.filters.endDate);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des clients',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async downloadCsvCustomers() {
      try {
        const statuses = !this.$route.query.status ? ['NEW', 'CONTRACT_SIGNATURE', 'PURCHASE_AGREEMENT', 'PENDING', 'R1', 'LAND_VISIT', 'ABORTED', 'LAND_SEARCH', 'R2', 'R3', 'R4'] : [this.$route.query.status];
        const filters = {
          statuses,
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
          search: this.$route.query.search,
          agencyId: this.$route.query.agencyId,
        };
        await customerApi.downloadCsv(filters);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du téléchargement du fichier CSV',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async downloadCsvEvents() {
      try {
        const filters = {
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
          agencyId: this.$route.query.agencyId,
        };
        await customerEventApi.downloadCsv(filters);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du téléchargement du fichier CSV',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    async filterProspects() {
      this.$router.push({
        query: {
          ...this.$route.query,
          status: this.filters.status || undefined,
          startDate: this.filters.startDate || undefined,
          endDate: this.filters.endDate || undefined,
        },
      });
    },
    async resetFilters() {
      this.filters = {
        status: null,
        startDate: null,
        endDate: null,
      };

      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          status: undefined,
          startDate: undefined,
          endDate: undefined,
        },
      });
    },
    closeFilterModal() {
      this.filters.status = this.$route.query.status || null;
      this.filters.startDate = this.$route.query.startDate || null;
      this.filters.endDate = this.$route.query.endDate || null;
    },
    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.customers-prospects
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    margin-right: 1rem
  .filter-container
    margin-right: 1rem
  .label-period
    margin-bottom: 1rem
  .label-datepicker
    margin-top: 0.5rem
  .mailto
    color: $blue-medium
</style>
