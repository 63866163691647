var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lands hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToCreateLand}},[_vm._v(" Ajouter un terrain ")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell auto search"},[_c('app-search',{attrs:{"placeholder":"Rechercher une référence, un lotissement, un lotisseur","size":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell small-2 search"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e(),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":_vm.initSearch}},[_vm._v("Réinitialiser")])],1):_vm._e(),_c('div',{staticClass:"cell auto"}),_c('div',{staticClass:"cell shrink filter-container"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterLands,"reset":_vm.resetFilters,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-radio-button',{attrs:{"label":"Trier par","name":"orderBy","value":_vm.$route.query.orderBy,"options":[
                  { name: 'price', label: 'Prix croissant' },
                  { name: 'width', label: 'Façade croissante' },
                  { name: 'name', label: 'Nom de commune' },
                  { name: 'lot', label: 'Numéro de lot croissant' },
                  { name: 'surface', label: 'Surface croissante' },
                  { name: 'surface_desc', label: 'Surface décroissante' } ]},model:{value:(_vm.filters.orderBy),callback:function ($$v) {_vm.$set(_vm.filters, "orderBy", $$v)},expression:"filters.orderBy"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-radio-button',{attrs:{"label":"Status du terrain","name":"status","value":_vm.$route.query.status,"options":[
                  { name: 'ACTIVE', label: 'Actif' },
                  { name: 'UNAVAILABLE', label: 'Indisponible' },
                  { name: 'SOLD', label: 'Vendu' }
                ]},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-search-city',{attrs:{"label":"Commune","placeholder":"Nantes - 44000","selectedPostalCode":_vm.selectedPostalCode},on:{"select":_vm.setPreSelectedPostalCode,"clear":_vm.resetSelectedPostalCode}})],1)])])],1),_c('div',{staticClass:"cell shrink align-self-middle"},[_c('app-toggle',{attrs:{"name":"toggle","icons":["list", "locate"],"selectedIcon":_vm.$route.query.view},on:{"input":_vm.updateRoute},model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}})],1)]),(_vm.$route.query.view === 'list' || !_vm.$route.query.view)?_c('div',[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.lands.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToLand},scopedSlots:_vm._u([{key:"city",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(" "+_vm._s(data.address.postalCodeInseeNumber.split('-')[0])+" - "+_vm._s(data.address.city)+" ")])]}},{key:"allotment",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.allotment ? data.allotment.name : ''))])]}},{key:"company",fn:function(ref){
                var data = ref.data;
return [_c('p',{class:{'no-registered-company-color' : !data.isCompanyRegistered}},[_vm._v(_vm._s(data.company ? data.company.name : ''))])]}},{key:"reference",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.reference))])]}},{key:"isAvailableForAds",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.booleanEnum[data.isAvailableForAds]))])]}},{key:"status",fn:function(ref){
                var data = ref.data;
return [_c('h5',{staticClass:"tag",class:[("tag-" + (_vm.status[data.status].tag))]},[(_vm.status[data.status].tag === 'success')?_c('success'):_vm._e(),(_vm.status[data.status].tag === 'warning')?_c('minus'):_vm._e(),(_vm.status[data.status].tag === 'neutral')?_c('price'):_vm._e(),_vm._v(" "+_vm._s(_vm.status[data.status].label)+" ")],1)]}},{key:"lotNumber",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.lotNumber))])]}},{key:"depth",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.width)+" m")])]}},{key:"surface",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.surface)+" m²")])]}},{key:"price",fn:function(ref){
                var data = ref.data;
return [_c('strong',{staticClass:"price"},[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.price, true)))])]}}],null,false,3793207804)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune donnée de disponible")])])],2)],1):_c('land-map',{attrs:{"lands":_vm.landsCoordinates,"height":500}}),(_vm.lands.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.lands.metadata.offset,"count":_vm.lands.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Liste des terrains")])])}]

export { render, staticRenderFns }