<template>
  <div class="project-draft">
    <form ref="project-draft" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="projectDraft && projectDraft.name"
            :items="[
              { route: { name: 'customers-projects-drafts'}, name: 'Avant-Projets'},
              { route: { name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: $route.params.projectDraftId }}, name: projectDraft.name}
            ]"
          />
        </div>
      </div>

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>{{ projectDraft && projectDraft.name ? projectDraft.name : '' }}</h1>
        </div>
        <div class="cell shrink save">
          <app-button icon="download" theme="secondary" size="large" @click="downloadPdf">Télécharger la notice</app-button>
        </div>
        <div class="cell shrink save" v-if="projectDraft">
          <app-button theme="warning" size="large" @click="openDeleteModal" :disabled="projectDraft.customerGenerated">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" @click="duplicate">Dupliquer</app-button>
        </div>
        <div class="cell shrink save" v-if="projectDraft && !projectDraft.customerGenerated">
          <router-link
            :to="{ name: 'customers-projects-drafts-projectDraftId-configuration', params: { projectDraftId: $route.params.projectDraftId } }">
            <app-button theme="primary" size="large">Modifier</app-button>
          </router-link>
        </div>
        <div class="cell shrink save" v-else>
          <app-button theme="primary" size="large" disabled>Modifier</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && projectDraft">
        <!-- DESCRIPTION & ILLUSTRATION -->
        <div class="card">
          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5 media-container">
              <img
                v-if="projectDraft.version.versionMedia"
                :src="projectDraft.version.versionMedia"
                class="illustration"
                alt="Image"
              />
              <div v-else class="illustration not-found">
                <upload />
              </div>
            </div>
            <div class="cell medium-7">
              <div class="grid-x row price">
                <div class="cell auto">
                  <h3>Prix du projet</h3>
                </div>
                <div class="cell auto"></div>
                <div class="cell shrink">
                  <h3>{{ utils.formatCentToEuro(projectDraft.totalPrice, true) }}</h3>
                </div>
              </div>
              <div class="row">
                <app-input v-model="projectDraft.name" label="Nom du projet" disabled />
              </div>
              <div class="row">
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-select label="Statut du projet" :options="projectDraftStatuses" v-model="projectDraft.status" disabled/>
                  </div>
                  <div class="cell auto">
                    <app-input :value="new Date(projectDraft.createdAt).toLocaleDateString('fr')" label="Date de création" disabled />
                  </div>
                </div>
              </div>
              <div class="row" v-if="projectDraft.customer.customerId">
                <label>
                  <span>Le prospect</span>
                </label>
                <app-card-link
                  :primary-route="{
                    route: {
                      name: 'customers-prospects-customerId',
                      params: { customerId: projectDraft.customer.customerId }
                    },
                    label: getCustomersNames(projectDraft.customer),
                  }"
                  :description="projectDraft.customer.email"
                />
              </div>
              <div class="row">
                <label>
                  <span>La version</span>
                </label>
                <app-card-link
                  :primary-route="{
                    route: {
                      name: 'versions-versionId-summary',
                      params: { versionId: projectDraft.version.versionId }
                    },
                    label: projectDraft.version.reference,
                  }"
                  :description="`${projectDraft.version.name} de ${projectDraft.totalSurface ? projectDraft.totalSurface.toFixed(2) : '0'} m²`"
                />
              </div>
              <div class="row" v-if="projectDraft.land && projectDraft.land.landId">
                <label>
                  <span>Le terrain</span>
                </label>
                <app-card-link
                  :primary-route="{
                    route: {
                      name: 'lands-landId',
                      params: { landId: projectDraft.land.landId }
                    },
                    label: projectDraft.land.allotment && projectDraft.land.allotment.allotmentId ? `${projectDraft.land.allotment.name} ${projectDraft.land.lotNumber ? `Lot n°${projectDraft.land.lotNumber}` : '' }` : projectDraft.land.reference,
                  }"
                  :description="`${projectDraft.land.address.city} (${projectDraft.land.address.postalCodeInseeNumber.split('-')[0]}) - ${projectDraft.land.surface} m² - ${ utils.formatCentToEuro(projectDraft.land.price, true)} `"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="summary-page page-print">
            <div class="page-title">
              <h1>Récapitulatif</h1>
            </div>
            <h2>Maison</h2>
            <div class="grid-x line">
              <div class="cell auto">
                <p>Coût initial de la maison</p>
              </div>
              <div class="cell shrink">
                <p v-if="projectDraft.initialPrice">
                  {{
                    utils.formatCentToEuro(
                      projectDraft.initialPrice -
                        (projectDraft.land && projectDraft.land.priceLandAdaptation
                          ? projectDraft.land.priceLandAdaptation
                          : 0) -
                        (projectDraft.land && projectDraft.land.priceLocalRegulation
                          ? projectDraft.land.priceLocalRegulation
                          : 0) -
                        (projectDraft.extensionsProperties.price
                          ? projectDraft.extensionsProperties.price
                          : 0),
                      true
                    )
                  }}
                </p>
              </div>
            </div>
            <div v-if="projectDraft.land && projectDraft.land.priceLandAdaptation" class="grid-x line">
              <div class="cell auto">
                <p>Frais adaptation au sol</p>
              </div>
              <div class="cell shrink">
                <p>{{ utils.formatCentToEuro(projectDraft.land.priceLandAdaptation, true) }}</p>
              </div>
            </div>
            <div v-if="projectDraft.land && projectDraft.land.priceLocalRegulation" class="grid-x line">
              <div class="cell auto">
                <p>Frais règlementation locale liés au terrain</p>
              </div>
              <div class="cell shrink">
                <p>{{ utils.formatCentToEuro(projectDraft.land.priceLocalRegulation, true) }}</p>
              </div>
            </div>
            <div class="grid-x line" v-if="projectDraft.extensionsProperties">
              <div class="cell auto">
                <p>Plus-value agrandissement</p>
              </div>
              <div class="cell shrink">
                <p>
                  {{
                    utils.formatCentToEuro(
                      projectDraft.extensionsProperties.price,
                      true
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="grid-x line">
              <div class="cell auto">
                <p>Options et équipements</p>
              </div>
              <div class="cell shrink">
                <p>
                  {{
                    utils.formatCentToEuro(
                      projectDraft.selectedOptions.reduce(
                        (a, option) =>
                          option.isActive &&
                          option.theme.isContractIncluded &&
                          option.category.categoryId !==
                            "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                          option.type === "STANDARD"
                            ? a + option.price
                            : a,
                        0
                      ),
                      true
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="total dark grid-x">
              <div class="cell shrink">
                <h4>Coût total de la maison</h4>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink">
                <h4>
                  {{
                    utils.formatCentToEuro(
                      projectDraft.initialPrice +
                        projectDraft.selectedOptions.reduce(
                          (a, option) =>
                            option.isActive &&
                            option.theme.isContractIncluded &&
                            option.category.categoryId !==
                              "c68c8545-09fd-4d57-a013-d4bfcfb673c2" &&
                            option.type === "STANDARD"
                              ? a + option.price
                              : a,
                          0
                        ),
                      true
                    )
                  }}
                </h4>
              </div>
            </div>
            <h2>Travaux réservés</h2>
            <div class="summary-total grid-x">
              <div class="cell shrink">
                <p><strong>Options et équipements hors contrat</strong></p>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink">
                <p>
                  <strong>{{
                    utils.formatCentToEuro(
                      projectDraft.selectedOptions.reduce(
                        (a, option) =>
                          option.isActive &&
                          !option.theme.isContractIncluded &&
                          option.type === "STANDARD" &&
                          option.category.categoryId !==
                            "c68c8545-09fd-4d57-a013-d4bfcfb673c2"
                            ? a + option.price
                            : a,
                        0
                      ),
                      true
                    )
                  }}</strong>
                </p>
              </div>
            </div>
            <h2>Terrain</h2>
            <div class="summary-total grid-x">
              <div class="cell shrink">
                <p><strong>Coût total du terrain</strong></p>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink">
                <p>
                  <strong>{{
                    utils.formatCentToEuro(projectDraft.landPrice, true)
                  }}</strong>
                </p>
              </div>
            </div>
            <h2>Frais annexes</h2>
            <div
              class="grid-x line"
              v-for="option in projectDraft.selectedOptions.filter(
                (option) => option.type === 'ANNEX' && option.isActive
              )"
              :key="option.optionId"
            >
              <div class="cell auto">
                <p>
                  {{ option.name }}
                  {{
                    option.descriptionCount ? `(${option.descriptionCount})` : ""
                  }}
                </p>
              </div>
              <div class="cell shrink">
                <p>{{ utils.formatCentToEuro(option.price, true) }}</p>
              </div>
            </div>
            <div class="summary-total grid-x">
              <div class="cell shrink">
                <p><strong>Coût total des frais annexes</strong></p>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink">
                <p>
                  <strong>{{
                    utils.formatCentToEuro(
                      projectDraft.selectedOptions.reduce(
                        (a, option) =>
                          option.type === "ANNEX" && option.isActive
                            ? a + option.price
                            : a,
                        0
                      ),
                      true
                    )
                  }}</strong>
                </p>
              </div>
            </div>
            <div class="summary-total dark grid-x">
              <div class="cell shrink">
                <p><strong>Coût total du projet</strong></p>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink">
                <p>
                  <strong>{{
                    utils.formatCentToEuro(projectDraft.totalPrice, true)
                  }}</strong>
                </p>
              </div>
            </div>
            <div class="annex-notes">
              <small
                class="annex-note"
                v-for="(option, index) in projectDraft.selectedOptions.filter(
                  (opt) =>
                    opt.descriptionCount && opt.type === 'ANNEX' && opt.isActive
                )"
                :key="`${index}-${option.optionId}`"
              >
                ({{ option.descriptionCount }}) : {{ option.description }} -
              </small>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5 media-container">
              <img
                v-if="blueprint && blueprint.url"
                :src="blueprint.url"
                class="illustration"
                alt="Image"
              />
              <div v-else class="illustration not-found">
                <upload />
              </div>
            </div>
            <div class="cell auto">
              <div class="grid-x surface-header">
                <div class="cell auto">
                  <h4>Surface totale</h4>
                  <h3>{{ `${projectDraft.totalSurface ? projectDraft.totalSurface.toFixed(2) : '0' } m²` }}</h3>
                </div>
                <div class="cell small-2">
                  <h4>Largeur façade</h4>
                  <h3>{{ (version.width + (firstExtensionCustomerWidth ? firstExtensionCustomerWidth : 0 ) + (secondExtensionCustomerWidth ? secondExtensionCustomerWidth : 0 )).toFixed(2) }} m</h3>
                </div>
                <div class="cell auto left-column">
                  <h4>Plus-value agrandissement</h4>
                  <h3>{{ `+ ${utils.formatCentToEuro(projectDraft.extensionsProperties.price, true)}` }}</h3>
                </div>
              </div>
              <div class="grid-x surface" v-for="surface in projectDraft.surfaces.filter((surface) => surface.area > 0)" :key="surface.surfaceId">
                <div class="cell auto">
                  <p>{{ rooms && rooms.length ? rooms.find((room) => room.roomId === surface.room.roomId).name : '' }}</p>
                </div>
                <div class="cell auto left-column">
                  <p class="price extended" v-if="surface.extensionSurface">{{ (surface.extensionSurface.toFixed(2)) }} m²</p>
                  <p class="price" v-else>{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="grid-x grid-margin-x row">
            <div class="cell small-4">
              <h3>Packs & options</h3>
                <app-card-link :primary-route="{ label: projectDraft.pack.name }"/>
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-accordion v-for="category in categories" :key="category.categoryId">
                <template slot="header">
                  <div class="grid-x grid-margin-x">
                    <div class="cell shrink">
                      <img v-if="category.icon" :src="category.icon" alt="icon" class="icon"/>
                      <div v-else class="icon"/>
                    </div>
                    <div class="cell auto">
                      <h3>{{ category.name }}</h3>
                    </div>
                    <div class="cell shrink">
                      <h3>Total: {{ utils.formatCentToEuro(getCategoryPrice(category.categoryId), true) }}</h3>
                    </div>
                  </div>
                </template>
                <template slot="content">
                  <div v-if="projectDraft.selectedOptions && projectDraft.selectedOptions.length && projectDraft.selectedOptions.find((selectedOption) => selectedOption.category.categoryId === category.categoryId && selectedOption.isActive)">
                    <div
                      v-for="option in projectDraft.selectedOptions.filter((selectedOption) => selectedOption.category.categoryId === category.categoryId && selectedOption.isActive)"
                      :key="option.optionId"
                      class="grid-x option">
                      <div class="cell auto">
                        <p>{{ option.name }}</p>
                      </div>
                      <div class="cell auto left-column">
                        <p>{{ option.type === 'BASE' ? 'inclus' :  `+ ${utils.formatCentToEuro(option.price, true)}` }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="empty-category">
                    <p>Pas d'options dans cette catégorie</p>
                  </div>
                </template>
              </app-accordion>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner-container" v-else>
        <app-spinner />
      </div>
    </form>
  </div>
</template>

<script>
import memberApi from '@/services/api/member';
import customerApi from '@/services/api/customer';
import projectDraftApi from '@/services/api/projectDraft';
import versionApi from '@/services/api/version';
import categoryApi from '@/services/api/category';
import lotSurveyApi from '@/services/api/lotSurvey';
import roomApi from '@/services/api/room';
import versionMediaApi from '@/services/api/versionMedia';
import auth from '@/services/auth';

import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import utils from '@/services/utils/utils';
import projectDraftStatuses from '@/services/data/projectDraftStatuses.json';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'project-draft',
  components: {
    Breadcrumb,
    upload,
  },
  metaInfo() {
    return {
      title: this.projectDraft ? `${this.projectDraft.customer.lastname} – Avant-projet` : 'Avant-projet',
    };
  },
  data() {
    return {
      projectDraft: null,
      me: null,
      isBrandAdmin: null,
      isLoading: null,
      utils,
      versionMedias: null,
      version: null,
      blueprint: null,
      projectDraftStatuses,
      rooms: null,
      categories: null,
      lotsSurveys: null,

      firstExtensionCustomerWidth: null,
      secondExtensionCustomerWidth: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getMe();
    await this.getProjectDraft();
    await this.getVersion();
    await this.getVersionMedias();
    await this.getRooms();
    await this.getLotsSurveys();
    await this.getCategories();

    this.isLoading = false;
  },
  watch: {
    async $route() {
      this.isLoading = true;
      await this.getProjectDraft();
      await this.getVersionMedias();
      this.isLoading = false;
    },
  },
  methods: {
    getCategoryPrice(categoryId) {
      let lotsSurveysSpecifications = null;
      let totalSpecifications = null;
      if (this.projectDraft.lotsSurveysSpecifications && this.projectDraft.lotsSurveysSpecifications.length) {
        lotsSurveysSpecifications = this.projectDraft.lotsSurveysSpecifications.filter((specification) => specification.categoryId === categoryId);
        totalSpecifications = lotsSurveysSpecifications && lotsSurveysSpecifications.length ? lotsSurveysSpecifications.reduce((a, b) => a + b.price, 0) : null;
      }
      let selectedOptions = null;
      let totalOptions = null;
      if (this.projectDraft.selectedOptions && this.projectDraft.selectedOptions.length) {
        selectedOptions = this.projectDraft.selectedOptions.filter((option) => option.category.categoryId === categoryId && option.isActive);
        totalOptions = selectedOptions && selectedOptions.length ? selectedOptions.reduce((a, b) => a + b.price, 0) : null;
      }
      return totalSpecifications + totalOptions;
    },
    async getLotsSurveys() {
      try {
        this.lotsSurveys = await lotSurveyApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getProjectDraft() {
      try {
        if (this.$route.params.projectDraftId) {
          this.projectDraft = await projectDraftApi.getById(this.$route.params.projectDraftId);

          if (this.projectDraft.extensionsProperties && this.projectDraft.extensionsProperties.isActive) {
            this.firstExtensionCustomerWidth = this.projectDraft.extensionsProperties.selectedByCustomer
              ? this.projectDraft.extensionsProperties.firstExtensionCustomerWidth : this.projectDraft.extensionsProperties.firstExtensionMaxWidth;

            this.secondExtensionCustomerWidth = this.projectDraft.extensionsProperties.selectedByCustomer
              ? this.projectDraft.extensionsProperties.secondExtensionCustomerWidth : this.projectDraft.extensionsProperties.secondExtensionMaxWidth;
          }
          if (this.projectDraft && this.projectDraft.surfaces.length) {
            this.projectDraft.surfaces = this.projectDraft.surfaces.map((surface) => {
              let extensionSurface = surface.area;
              if (this.firstExtensionCustomerWidth && surface.firstExtensionDepth) {
                extensionSurface += this.firstExtensionCustomerWidth * (surface.firstExtensionDepth ? surface.firstExtensionDepth : 0);
              }
              if (this.secondExtensionCustomerWidth && surface.secondExtensionDepth) {
                extensionSurface += this.secondExtensionCustomerWidth * (surface.secondExtensionDepth ? surface.secondExtensionDepth : 0);
              }

              return {
                ...surface,
                extensionSurface: extensionSurface !== surface.area ? extensionSurface : null,
              };
            });
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'avant-projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategories() {
      try {
        this.categories = await categoryApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRooms() {
      try {
        this.rooms = await roomApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des pièces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVersion() {
      try {
        if (this.projectDraft && this.projectDraft.version) {
          this.version = await versionApi.getById(this.projectDraft.version.versionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    async getVersionMedias() {
      if (this.projectDraft && this.projectDraft.version && this.projectDraft.version.versionId) {
        try {
          if (this.$route.params.projectDraftId) {
            this.versionMedias = await versionMediaApi.getAll(this.projectDraft.version.versionId);
            if (this.versionMedias && this.versionMedias.length) {
              this.blueprint = this.versionMedias.find((media) => media.type === 'BLUEPRINT');
            }
          }
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération de l\'avant-projet',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async duplicate() {
      if (this.$route.params.projectDraftId) {
        this.isLoading = true;
        try {
          const newProjectDraft = await projectDraftApi.duplicate(this.$route.params.projectDraftId);
          this.$notification.show({ text: 'Cet avant-projet a été dupliqué avec succès !' });

          this.$router.push({ name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: newProjectDraft.projectDraftId } });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la duplication des avant-projets',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
    async downloadPdf() {
      try {
        const query = `token=${auth.getToken()}`;
        window.open(`${process.env.VUE_APP_API_URL}/project-drafts/${this.projectDraft.projectDraftId}/pdf?${query}`, '_blank');
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la génération du PDF',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer l\'avant-projet',
        text: 'Êtes vous sûr de vouloir supprimer cet avant-projet ?',
        confirmText: 'Supprimer l\'avant-projet',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteProjectDraft();
        },
      });
    },
    async deleteProjectDraft() {
      if (this.$route.params.projectDraftId) {
        this.isLoading = true;
        try {
          await projectDraftApi.delete(this.$route.params.projectDraftId);
          this.$notification.show({ text: 'Cet avant-projet a été supprimé avec succès !' });

          this.$router.push({ name: 'customers-projects-drafts' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de l\'avant-projet',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.project-draft
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
    h3
      margin: 0 0 16px 0
      font-weight: 500
  .media-container
    img
      width: 100%
    .illustration
      border-radius: 4px
    .not-found
      @include centered-container
      border: 1px solid $info
      height: 200px
      svg
        width: 70px
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
  .left-column
    text-align: right
  .surface-header
    padding-bottom: 10px
  .surface
    border-bottom: 1px solid $line
    padding-bottom: 16px
  label span
    @include heading-xs
    color: $info
    padding-bottom: 4px
    line-height: 14px
  .app-accordion
    .icon
      width: 24px
    .option
      padding: 16px
      border-bottom: 1px solid $line
    h3
      margin: 0
      color: $title
  .empty-category
    padding: 16px
  .extended
    color: $primary

  .summary-page
    padding: 0 15px
    .page-title
      margin-bottom: 5px
      padding: 10px 20px
      background-color: $blue-dark
      border-radius: 8px
      h1
        color: white
        font-size: 1.4rem
    h2
      margin-top: 5px
      padding: 5px 20px
      font-size: 1.25rem
    h4
      font-size: 1rem
    .line
      padding: 5px 20px
      margin-bottom: 5px
      border-bottom: solid 1px $gray-very-light
    .total
      margin: 5px 0
      padding: 10px 20px
      background-color: $gray-very-light
      border-radius: 4px
    .summary-total
      margin-top: 5px
      padding: 10px 20px
      font-size: 1rem
      background-color: $gray-very-light
      border-radius: 4px
      p strong
        font-size: 1rem
    .annex-notes
      line-height: 75%
    p, span
      margin: 0
      font-size: 0.9rem
      line-height: 18px
      color: #3E4558
</style>
