var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customers-unqualified hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("À qualifier "+_vm._s(_vm.customers && _vm.customers.metadata && _vm.customers.metadata.count ? ("(" + (_vm.customers.metadata.count) + ")") : ''))])]),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToCustomer}},[_vm._v("Ajouter un contact")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell shrink search"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher un contact"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell small-2 search"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e(),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":_vm.initSearch}},[_vm._v("Réinitialiser")])],1):_vm._e(),_c('div',{staticClass:"cell shrink filter-container"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterCustomers,"reset":_vm.resetFilters,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-select',{attrs:{"label":"Origin","options":[
                  { name: 'PHONE', label: 'Téléphone' },
                  { name: 'WEBSITE', label: 'Site internet' },
                  { name: 'MAIL', label: 'Email' },
                  { name: 'BLOG', label: 'Blog' },
                  { name: 'UNKNOWN', label: 'Inconnue' },
                  { name: 'PHONE_SCORIMMO', label: 'Scorimmo' },
                  { name: 'LEBONCOIN_UBIFLOW', label: 'Ubiflow - Leboncoin - TMA' },
                  { name: 'LEBONCOIN_UBIFLOW_TA', label: 'Ubiflow - Leboncoin - TA' },
                  { name: 'LEBONCOIN_UBIFLOW_TMM', label: 'Ubiflow - Leboncoin - TMM' },
                  { name: 'LES_TERRAINS_UBIFLOW', label: 'Ubiflow - Les-Terrains.com ' },
                  { name: 'MOBILE_CONFIG', label: 'Configurateur Mobile' },
                  { name: 'OTHER_UBIFLOW', label: 'Ubiflow - Autres' },
                  { name: 'MANUAL_AGENCY_CREATION', label: 'Création Contact Agence' },
                  { name: 'EXPERT_CONFIG', label: 'Configurateur expert' },
                  { name: 'AGENCY_CONTACT_PAGE', label: 'Formulaire - Page Agence' }
                ]},model:{value:(_vm.filters.firstContact),callback:function ($$v) {_vm.$set(_vm.filters, "firstContact", $$v)},expression:"filters.firstContact"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-label',{staticClass:"label-period"},[_vm._v("Période")]),_c('app-label',[_vm._v("Du")]),_c('app-datepicker',{attrs:{"value":_vm.filters.startDate,"language":_vm.datePickerLanguage,"monday-first":"","required":!!(_vm.filters.endDate)},on:{"input":function($event){return _vm.handleFilterDateInput('startDate', $event)}}}),_c('app-label',{staticClass:"label-datepicker"},[_vm._v("Au")]),_c('app-datepicker',{attrs:{"value":_vm.filters.endDate,"language":_vm.datePickerLanguage,"monday-first":"","required":!!(_vm.filters.startDate)},on:{"input":function($event){return _vm.handleFilterDateInput('endDate', $event)}}})],1)])])],1)]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.customers.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToCustomer},scopedSlots:_vm._u([{key:"name",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.lastname))])]}},{key:"firstname",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.firstname))])]}},{key:"phone",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.phone.replace(/\D/g, "").replace(/(\d{2})(?=\d)/g, "$1 ")))])]}},{key:"email",fn:function(ref){
                var data = ref.data;
return [_c('p',[_c('span',{staticClass:"mailto",on:{"click":function($event){$event.stopPropagation();return _vm.openMailto(data.email)}}},[_vm._v(_vm._s(data.email))])])]}},{key:"firstContact",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.customerFirstContacts.find(function (c) { return c.name === data.firstContact; }).label))])]}},{key:"createdAt",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs.utc(data.createdAt).format('DD/MM/YYYY')))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.customers.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.customers.metadata.offset,"count":_vm.customers.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }